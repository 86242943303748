import axios from './axios';

export const get_regions = async () => {
  try {
    const response = await axios.get('/valid_regions');
    // console.log(response)
    // Capitalize the regions
    response.data.res = response.data.res.map(region => region.charAt(0).toUpperCase() + region.slice(1));
    return response.data.res;
  } catch (error) {
    throw new Error('Error loading regions' || error.message.data);
  }
};


export const getUserData = async () => {
  try {
    const response = await axios.get('/user/me');
    return response.data;
  } catch (error) {
    console.error("Error loading user data")
    return null;
  }
};

export const getPersonData = async (person_id) => {
  try {
    const response = await axios.get('/person/get/' + person_id);
    return response.data;
  } catch (error) {
    console.error("Error loading person data")
    return null;
  }
};

export const getAdminData = async () => {
  let out = {}

  try {
    let response = await axios.get('/rubric/templates');
    // append to out
    out["templates"] = response.data["templates"]

    response = await axios.get('/student/count');
    out["studentCount"] = response.data.count

    response = await axios.get('/judge/count');
    out["judgeCount"] = response.data.count

    response = await axios.get('/project/count');
    out["projectCount"] = response.data.count

    return out;
  } catch (error) {
    console.error("Error loading admin data")
    console.log(error.toString())
    return null;
  }
};

export const getTeamMembers = async (project_id) => {
    try {
        const response = await axios.get('/team/project/' + project_id);
        return response.data;
    } catch (error) {
      console.error("Error loading team members")
      return null;
    }
}

export const getJudgeRubricData = async (person_id) => {
  try {
    const response = await axios.get('/rubric/judge/' + person_id);
    let rubric_data = response.data;
    if(rubric_data === null){
      console.error("Error loading rubric data")
      return null;
    }
    for (let i = 0; i < rubric_data.rubrics.length; i++){
      const team = await getTeamMembers(rubric_data.rubrics[i].project_id);
      if(team === null)
        return null;
      rubric_data.rubrics[i].team_members = team;
    }
    return rubric_data;
  } catch (error) {
    console.error("Error loading judge rubric data")
    return null;
  }
};


export const loadAdminData = async (userData, setUserData) => {
  const adminData = await getAdminData()
  if (adminData !== null) {
    userData.adminData = adminData;
    setUserData(userData)
  }
  else {
    console.error("Error loading admin data to state")
  }
}

export const loadUserDataComplete = async(setUserData) => {
  const userData = await getUserData();
  if(userData !== null){

    if (userData.person_id !== null){
      const personData = await getPersonData(userData.person_id);
      if(personData !== null){
        userData.personData = personData;
        if(userData.personData.type === "judge" && userData.is_admin === 0){
          const rubricData = await getJudgeRubricData(userData.person_id);
          if(rubricData !== null)
            userData.rubricData = rubricData
          else{
            userData.rubricData = null;
          }
        }

      }
      else {
        userData.personData = null;
        userData.rubricData = null;
        userData.adminData = null;
      }
      if(userData.is_admin > 0){
        const adminData = await getAdminData();
        if(adminData !== null) {
            userData.adminData = adminData
        }
        else{
            userData.adminData = null;
        }
      }
    }
    else {
      userData.personData = null;
      userData.rubricData = null;
      userData.adminData = null;
    }
      if(userData.is_admin > 0){
          const adminData = await getAdminData();
          if(adminData !== null) {
              userData.adminData = adminData
          }
          else{
              userData.adminData = null;
          }
      }
  }
  if(userData === null){
    console.error('Severe error attempting to load user data');
    await logout(setUserData)
    return null;

    // navigate('/login', { state: { message: 'Severe error attempting to load user data', severity: 'error' } });
  }
  // console.log(userData);
  setUserData(userData);
}

export const updateRubric = async (public_feedback, private_feedback, recommend, rubric_id, is_valid = true, update_completed_status = true) => {
    try {
        const response = await axios.post('/rubric/update/by_id/' + rubric_id, {
          pub_feed: public_feedback,
          priv_feed: private_feedback,
          recommended: recommend,
          completed: (is_valid? 1 : 0),
          update_completed_status: (update_completed_status? 1: 0)
        });
        return response.data;
    } catch (error) {
        console.error("Error updating rubric")
        return null;
    }
}

export const massSetCategories = async (rubric_id, data_arr) => {
  try {
    const response = await axios.post('/rubric/' + rubric_id + '/score/mass_update', {
      rubric_id: rubric_id,
      scores: data_arr,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating rubric")
    return null;
  }
}

export const activateRubricTemplate = async (rubric_id) => {
  try {
    const response = await axios.post('/rubric/template/activate/' + rubric_id);
    return response.data;
  }
    catch (error) {
        console.error("Error activating rubric template")
        return null;
    }
}

export const deactivateRubricTemplate = async (rubric_id) => {
  try {
    const response = await axios.post('/rubric/template/deactivate/' + rubric_id);
    return response.data;
  }
  catch (error) {
    console.error("Error activating rubric template")
    return null;
  }
}

export const setRubricDefault = async (rubric_id) => {
  try {
    const response = await axios.post("/rubric/template/set_default/" + rubric_id);
    return response.data;
  }
    catch (error) {
        console.error("Error setting rubric as default")
        return null;
    }
}

export const deleteRubricTemplate = async (rubric_id) => {
  try {
    const response = await axios.delete("/rubric/template/delete/" + rubric_id);
    return response.data;
  }
    catch (error) {
        console.error("Error deleting rubric template")
        return null;
    }
}


export const getTemplateDetails = async (template_id, hook = null) => {
  try {
    const response = await axios.get('/rubric/template/get/' + template_id);
    if (hook !== null) {
      hook(response.data);
    }
    return response.data;
  }
    catch (error) {
        console.error("Error loading template details")
        return null;
    }
}

export const createRubricTemplateCategory = async (rubric_id, name) => {
    try {
        const response = await axios.post('/rubric/template/get/' + rubric_id + '/add_category', {
            category_name: name,
            parent_category_id: null,
            template_id: rubric_id,
        });
        return response.data;
    }
    catch (error) {
        console.error("Error creating category")
        return null;
    }
}

export const createRubricTemplateCategoryFull = async (rubric_id, parent_cat_id, name, max_score, weight, description = null) => {
    if (description === undefined || description === null) {
        description = "";
    }
    try {
        const response = await axios.post('/rubric/template/get/' + rubric_id + '/add_category', {
            category_name: name,
            category_description: description,
            parent_category_id: parent_cat_id,
            template_id: rubric_id,
            max_score: max_score,
            weight: weight,
        });
        return response.data;
    }
    catch (error) {
        console.error("Error creating category: " + error)
        console.error({
            category_name: name,
            category_description: description,
            parent_category_id: parent_cat_id,
            template_id: rubric_id,
            max_score: max_score,
            weight: weight,
        })
        return null;
    }
}

export const deleteRubricTemplateCategory = async (category_id, rubric_id) => {
    try {
        const response = await axios.delete('/rubric/template/category/' + rubric_id + '/delete/' + category_id);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting category")
        return null;
    }
}

export const updateRubricTemplateCategory = async (category_id, rubric_id, changes) => {
    try {
        const response = await axios.post('/rubric/template/category/' + rubric_id + '/update/' + category_id, changes);
        return response.data;
    }
    catch (error) {
        console.error("Error updating category")
        return null;
    }
}

export const createRubricTemplate = async (name, desc) => {
    try {
        const response = await axios.post('/rubric/template/create', {
            name: name,
            description: desc,
        });
        return response.data;
    }
    catch (error) {
        console.error("Error creating category")
        return null;
    }
}

export const parseData = async (data) => {
    try {
        const response = await axios.post('/parser/parse_data', data);
        return response.data;
    }
    catch (error) {
        console.error("Error parsing data")
        return null;
    }
}

export const fetchStudents = async(max, skip) => {
    try {
        const response = await axios.get('/students/select/' + max + '/' + skip);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching all students")
        return null;
    }
}

export const fetchJudges = async(max, skip) => {
    try {
        const response = await axios.get('/judges/select/' + max + '/' + skip);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching all judges")
        return null;
    }
}

export const fetchProjects = async(max, skip) => {
    try {
        const response = await axios.get('/projects/select/' + max + '/' + skip);
        return response.data;
    }
    catch (error) {
        console.error("Error fetching all projects")
        return null;
    }
}


export const checkIn = async(checkedIn, studentID) => {
    try {
        if (checkedIn === true) {
            const response = await axios.post('/student/check-in/' + studentID);
            return response.data;
        }
        else if (checkedIn === false) {
            const response = await axios.post('/student/check-out/' + studentID);
            return response.data;
        }
    }
    catch (error) {
        console.error("Error fetching all projects")
        return null;
    }
}

export const deletePerson = async(person_id) => {
    try {
        const response = await axios.delete('/person/delete/' + person_id);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting person")
        return null;
    }
}

export const deleteProject = async(project_id) => {
    try {
        const response = await axios.delete('/project/delete/' + project_id);
        return response.data;
    }
    catch (error) {
        console.error("Error deleting project")
        return null;
    }
}


export const logout = async (setUserData) => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiry');
  setUserData(null)
}