import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getName} from "../judge/JudgeDashboard";

import LeftSidebar from "../sidebar/LeftSidebar";
import {loadUserDataComplete} from "../../api/api";
import RightJudgeSidebar from "../sidebar/RightJudgeSidebar";
import {Box, Button, Divider, Drawer, IconButton, Typography} from "@mui/material";
import RubricView from "./RubricView";
import '../../styles/dashboard.css';
import MenuIcon from "@mui/icons-material/Menu";
import Title from "../reusable/Title";

const Rubric = (props) => {
    const navigate = useNavigate();

    let name = getName(props.userData)

    const [loadedData, setLoadedData] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    let admin = false;
    let rubrics = []
    if(props.userData === null || props.userData === undefined){
        // only run once by using useRef
        if(loadedData === false){
            setLoadedData(true);
            loadUserDataComplete(props.setUserData)
        }

        return (
            <div>
                <h1>Rubric</h1>
                <p>Loading...</p>
                <p>If you are not logged in you should be redirected, you will not be able to access this page logged out.</p>
            </div>
        );
    }
    else if (props.userData.rubricData === undefined) {
        return (
            <div>
                <h1>Error</h1>
                <p>You likely don't have permission to view this rubric.</p>
            </div>
        )
    }
    else{
        admin = props.userData.is_admin > 0;
        rubrics = props.userData.rubricData.rubrics
    }

    // get rubric from url, it comes after last slash
    const url = window.location.href;
    const project_id = url.substring(url.lastIndexOf('/') + 1);
    const relevant_rubric = rubrics.filter((rubric) => String(rubric.project_id).toString() === project_id)[0]

    // console.log("Relevant Rubric:")
    // console.log(relevant_rubric)

    const navToDashboard = () => {
        navigate('/auth/dashboard/');
    };

    if(relevant_rubric === undefined){
        return (<>
            <p>Error loading rubric page, likely due to a bad URL</p>
            <Button variant="contained" sx={{ mb: 1 }} onClick={navToDashboard}>Back to Dashboard</Button>
        </>)
    }
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };
    return (
        <div className="container">
            <IconButton
                className="dashboard-drawer"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{position: 'absolute', top: 10, left: 10}}
            >
                <div className="dashboard-drawer">
                    <MenuIcon/>
                </div>
            </IconButton>
            <Drawer className="dashboard-drawer" anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{width: 200, margin: 1, marginRight: 4}}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <RightJudgeSidebar userData={props.userData} setUserData={props.setUserData}/>
                    <Divider sx={{my: 2}}/>
                    <LeftSidebar name={name} setUserData={props.setUserData}/>
                </Box>
            </Drawer>
            {/* Left Sidebar */}
            <div className="left-sidebar">
                {!admin ? <LeftSidebar name={name} setUserData={props.setUserData}/> : <p>admin</p>}
            </div>

            <Box className="main-content" sx-={{flexDirection: "column", alignItems: "center"}}>
                <Title/>

                <Typography variant="h5">Grade Project</Typography>
                <Typography>Project: {relevant_rubric.project_name} (#{relevant_rubric.project_id})</Typography>
                <RubricView userData={props.userData} rubric={relevant_rubric}/>

            </Box>
            <div className="right-sidebar">
                {!admin ? <RightJudgeSidebar name={name} userData={props.userData} setUserData={props.setUserData}/> :
                    <p>admin</p>}
            </div>


        </div>
    );
};

export default Rubric;