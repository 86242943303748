import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    IconButton,
    Tabs,
    Tab,
    TextField,
    Typography,
    Drawer,
    Divider,
    CircularProgress,
    Tooltip,
    useTheme,
    useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
import { Add, Edit, Delete, Menu as MenuIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import LeftSidebar from "../sidebar/LeftSidebar";
import RightAdminSidebar from "../sidebar/RightAdminSidebar";
import {deleteProject, deletePerson, fetchJudges, fetchProjects, fetchStudents} from "../../api/api";
import Title from "../reusable/Title";

const blacklistColumns = ['type', 'region', 'expertise', 'keywords', 'reg_exp', 'nat_exp'];
const mobileWhitelistColumns = ['id', 'actions', 'last_name', 'email', 'title', 'description'];

const RegistrationManagement = (props) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [data, setData] = useState({ judges: [], projects: [], students: [] });
    const [loading, setLoading] = useState({ judges: true, projects: true, students: true });
    const [search, setSearch] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState({ type: '', id: null });

    const navigate = useNavigate();
    const name = props.userData?.personData?.first_name || props.userData?.username || "?";

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // Toggle Drawer
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    // Handle Tab Change
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    // Handle Search Input Change
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    // Handle Add Button Click
    const handleAdd = (type) => {
        navigate(`/add-${type}`);
    };

    // Handle Edit Button Click
    const handleEdit = (type, id) => {
        navigate(`/edit-${type}/${id}`);
    };

    const openDeleteDialog = (type, id) => {
        setDeleteInfo({ type, id });
        setDialogOpen(true);
    };

    const confirmDelete = async () => {
        const { type, id } = deleteInfo;
        if ((type === "students") || (type === "judges")) {
            const res = await deletePerson(id);
            if (res !== true) return;
        }
        if (type === "projects") {
            const res = await deleteProject(id);
            if (res !== true) return;
        }
        setData((prevData) => ({
            ...prevData,
            [type]: prevData[type].filter((item) => item.id !== id),
        }));
        setDialogOpen(false);
    };

    const handleDelete = (type, id) => {
        openDeleteDialog(type, id);
    };


    // Filter Data Based on Search Input
    const filteredData = (type) => {
        return data[type].filter((item) =>
            Object.values(item).some((val) =>
                String(val).toLowerCase().includes(search.toLowerCase())
            )
        );
    };

    // Fetch data using useEffect
    useEffect(() => {
        // Fetch Judges
        setLoading((prev) => ({ ...prev, judges: true }));
        fetchJudges(-1, 0).then((response) => {
            setData((prevData) => ({ ...prevData, judges: response }));
            setLoading((prev) => ({ ...prev, judges: false }));
        }).catch((error) => {
            setLoading((prev) => ({ ...prev, judges: false }));
        });

        // Fetch Students
        setLoading((prev) => ({ ...prev, students: true }));
        fetchStudents(-1, 0).then((response) => {
            response.forEach((student) => {
                student.checked_in = student.checked_in === 1 ? "Yes" : "No";
            });

            setData((prevData) => ({ ...prevData, students: response }));
            setLoading((prev) => ({ ...prev, students: false }));
        }).catch((error) => {
            setLoading((prev) => ({ ...prev, students: false }));
        });

        // Fetch Projects
        setLoading((prev) => ({ ...prev, projects: true }));
        fetchProjects(-1, 0).then((response) => {
            setData((prevData) => ({ ...prevData, projects: response }));
            setLoading((prev) => ({ ...prev, projects: false }));
        }).catch((error) => {
            setLoading((prev) => ({ ...prev, projects: false }));
        });
    }, []);

    // Column Overrides for Custom Rendering and Header Names
    const columnOverrides = {
        id: { headerName: 'ID' },
        first_name: { headerName: 'First Name' },
        last_name: { headerName: 'Last Name' },
        email: { headerName: 'Email' },
        highest_education_obtained: { headerName: 'Education' },
        checked_in: {
            headerName: "Checked In",
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value || ''}>
                        <span>{params.value}</span>
                    </Tooltip>
                );
            }
        },
        project_id: { headerName: "Project ID" },
        students: {
            headerName: 'Students',
            renderCell: (params) => {
                if (Array.isArray(params.value) && params.value.length > 0) {
                    return (
                        <Box
                            sx={{
                                maxHeight: '80px',
                                overflowY: 'auto',
                                pr: 1,
                            }}
                        >
                            {params.value.map((student) => (
                                <Box key={student.id} sx={{ mb: 1 }}>
                                    <Typography variant="body2" component="div">
                                        <strong>{student.first_name} {student.last_name}</strong> (ID: {student.id})
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    );
                }
                return <Typography variant="body2">No Students</Typography>;
            }
        },
        description: {
            headerName: 'Description',
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value || ''}>
                        <span>{params.value}</span>
                    </Tooltip>
                );
            }
        },
    };

    // Function to get the initial column visibility model
    const getInitialColumnVisibilityModel = (columns) => {
        const model = {};
        columns.forEach((col) => {
            if (isSmallScreen && !mobileWhitelistColumns.includes(col.field)) {
                model[col.field] = false; // hidden
            } else {
                model[col.field] = true; // visible
            }
        });
        return model;
    };

    // Function to Dynamically Generate Columns
    const getColumns = (type) => {
        if (data[type].length === 0) return [];

        const dataFields = Object.keys(data[type][0]).filter(
            (key) => !blacklistColumns.includes(key)
        );

        // Calculate maximum content length for each field
        const maxContentLengths = {};
        dataFields.forEach((field) => {
            let maxLength = (columnOverrides[field]?.headerName || field).length; // Start with header name length
            data[type].forEach((row) => {
                const value = row[field] !== null && row[field] !== undefined ? String(row[field]) : '';
                if (value.length > maxLength) {
                    maxLength = value.length;
                }
            });
            maxContentLengths[field] = maxLength;
        });

        const columns = dataFields
            .sort((a, b) => a === 'id' ? -1 : b === 'id' ? 1 : 0)
            .map((field) => {
                if (columnOverrides[field]?.hide) return null;

                const headerName =
                    columnOverrides[field]?.headerName ||
                    field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ');

                // Define maximum width (e.g., current default width or a specific max)
                const maxWidth = 300; // Adjust this value as needed
                const minWidth = 80; // Minimum width for a column
                const calculatedWidth = Math.min(Math.max(maxContentLengths[field] * 8 + 32, minWidth), maxWidth); // Add some padding

                return {
                    field,
                    headerName,
                    width: calculatedWidth + 10,
                    minWidth: minWidth,
                    maxWidth: maxWidth,
                    renderCell: columnOverrides[field]?.renderCell || ((params) => {
                        return (
                            <Tooltip title={params.value || ''}>
                                <span>{params.value}</span>
                            </Tooltip>
                        );
                    }),
                };
            })
            .filter(Boolean);

        // Add the actions column with fixed width
        columns.push({
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 150, // Fixed width
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleEdit(type, params.id)}>
                        <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(type, params.id)}>
                        <Delete />
                    </IconButton>
                </>
            ),
        });

        return columns;
    };

    // Memoized versions of columns and initialState
    const judgesColumns = useMemo(() => getColumns('judges'), [data.judges, isSmallScreen]);
    const studentsColumns = useMemo(() => getColumns('students'), [data.students, isSmallScreen]);
    const projectsColumns = useMemo(() => getColumns('projects'), [data.projects, isSmallScreen]);

    // Memoized initialState for each type
    const judgesInitialState = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: getInitialColumnVisibilityModel(judgesColumns),
        },
    }), [isSmallScreen, judgesColumns]);

    const studentsInitialState = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: getInitialColumnVisibilityModel(studentsColumns),
        },
    }), [isSmallScreen, studentsColumns]);

    const projectsInitialState = useMemo(() => ({
        pagination: {
            paginationModel: {
                pageSize: 10,
            },
        },
        columns: {
            columnVisibilityModel: getInitialColumnVisibilityModel(projectsColumns),
        },
    }), [isSmallScreen, projectsColumns]);

    // Adjust DataGrid styles
    const dataGridStyles = {
        '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignItems: 'center',
        },
        '& .MuiDataGrid-columnHeader': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    const renderTable = (type, columns, initialState) => (
        <Box sx={{ flex: '1 1 auto', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{marginTop: "10px", marginBottom: "10px"}}>
                <TextField label="Search" variant="outlined" value={search} onChange={handleSearchChange} />

            </Box>
            <Box sx={{marginTop: "10px", marginBottom: "10px"}}>
                <Button
                    sx={{ marginRight: "10px" }}
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => handleAdd(type)}
                >
                    Add {type.charAt(0).toUpperCase() + type.slice(1)}
                </Button>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: "#4fa23f" }}
                    startIcon={<Add />}
                    onClick={() => navigate("bulk-add")}
                >
                    Bulk Add
                </Button>
            </Box>

            <Box sx={{ flex: '1 1 auto', width: '100%', pb: 3, overflowX: 'auto' }}>
                {loading[type] ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ minWidth: 750 }}>
                        <DataGrid
                            key={isSmallScreen ? 'small' : 'large'} // Force re-mount when screen size changes
                            rows={filteredData(type)}
                            columns={columns}
                            initialState={initialState}
                            pageSizeOptions={[10, 20, 50]}
                            sortingOrder={['asc', 'desc']}
                            rowHeight={60}
                            sx={dataGridStyles}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );

    return (
        <div className="container" style={{ display: 'flex', height: '100vh' }}>
            {isSmallScreen && (
                <IconButton
                    className="dashboard-drawer"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ position: 'absolute', top: 10, left: 10 }}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Drawer
                className="dashboard-drawer"
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{ width: 200, margin: 1, marginRight: 4 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <LeftSidebar name={name} setUserData={props.setUserData} />
                    <Divider sx={{ my: 2 }} />
                    <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
                </Box>
            </Drawer>

            <div className="left-sidebar" style={{ flex: '0 0 200px', overflowY: 'auto' }}>
                <LeftSidebar name={name} setUserData={props.setUserData} />
            </div>

            <div className="main-content" style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                <Title/>

                <div style={{ marginBottom: "5px", padding: '0 16px' }}>
                    {(isSmallScreen)?
                        <Typography variant="h5">Registration Management</Typography>
                        :
                        <Typography variant="h3">Registration Management</Typography>}
                    <Typography variant="body1" sx={{ fontSize: "15px" }}>
                        Add, remove, modify Judges, Students, and Projects
                    </Typography>
                </div>

                <Box sx={{ flex: '1 1 auto', width: '100%', p: 2, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Judges" />
                        <Tab label="Students" />
                        <Tab label="Projects" />
                    </Tabs>
                    {tabIndex === 0 && renderTable('judges', judgesColumns, judgesInitialState)}
                    {tabIndex === 1 && renderTable('students', studentsColumns, studentsInitialState)}
                    {tabIndex === 2 && renderTable('projects', projectsColumns, projectsInitialState)}
                </Box>
                <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                >
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this entry?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmDelete} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className="right-sidebar" style={{ flex: '0 0 200px', overflowY: 'auto' }}>
                <RightAdminSidebar userData={props.userData} setUserData={props.setUserData} />
            </div>
        </div>
    );
};

export default RegistrationManagement;
